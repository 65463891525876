* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.About {
    position: relative;
    width: 100%;
    background-color: #0a1724;
    padding-top: 5vw;
    padding-left: 4vw;
    padding-bottom: 5vw;
}



.info {
    background-color: #0a1724;
    display: block;
}

.info h1 {
    font-size: 4vw;
    color: aliceblue;
}

.info h2 {
    font-size: 3vw;
    color: #21ef00;
    margin-top: 1vw;
}

.typing {
    color: white;
}

.info p {
    color: aliceblue;
    font-size: 1vw;
    margin-top: 1vw;
    line-height: 1.5vw;
    width: 70%;
}

.data {
    display: flex;
    margin-top: 3vw;
}

.data span {
    font-size: border;
    color: #21ef00;
    font-size: 1.3vw;
    padding-right: 10px;
}

.data p {
    border-bottom: 0.3vw solid gainsboro;
    color: aliceblue;
    font-size: 1.2vw;
    margin-top: 30px;
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
}

.data-w {
    display: flex;
    gap: 5vw;
}

.data a {
    list-style: none;
    color: aliceblue;
}

.data a:hover {
    color: #21ef00;
    cursor: pointer;
}


.resume {
    display: block;
    position: relative;
    margin-top: 10vw;
}

.title {
    color: #21ef00;
    font-size: 4vw;
    width: 90%;
    padding-bottom: 3vw;
    border-bottom: 3px solid aliceblue;
    padding-left: 4vw;
    margin-bottom: 4vw;
}


.hackbox {
    border-left: 3px solid aliceblue;
    padding-left: 5vw;
    padding-top: 3vw;
}

.hackbox h1 {
    color: #21ef00;
    font-size: 2vw;
    margin-bottom: 2vw;
}

.hackbox p {
    width: 80%;
    line-height: 1.5vw;
    color: aliceblue;
    font-size: 1vw;
}

.hackbox button {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    background-color: #21ef00;
    position: absolute;
    left: -0.7vw;
    margin-top: 0.5vw;
    border: none;
}

.skpw {
    display: flex;
    gap: 10vw;
    width: 100%;
}

.skpw p {
    padding-top: 0.3vw;
}



























@media screen and (max-width: 896px) {
    .About {
        width: 100%;
        margin-top: 30vw;
        padding-bottom: 20vw;
    }

    .data-w {
        display: block;
    }

    .data {
        display: block;
        width: 100%;
        margin-top: 20vw;
    }


    .info h1 {
        font-size: 8vw;
    }

    .info h2 {
        font-size: 6vw;
        margin-top: 4vw;
    }

    .info p {
        font-size: 4vw;
        line-height: 7vw;
        width: 90%;
        margin-top: 6vw;
    }

    .data span {
        font-size: 5vw;
    }

    .data p {
        font-size: 4vw;
        border-bottom: 1vw solid white;
    }

    .data-r {
        margin-top: 5vw;
    }


    .resume {
        display: block;
        width: 100%;
    }

    .title {
        font-size: 8vw;
        border-bottom: 0.8vw solid white;
        margin-bottom: 10vw;
        padding-bottom: 5vw;
        margin-top: 25vw;
    }

    .hackbox {
        border-left: 0.8vw solid white;
        padding-left: 10vw;
    }

    .hackbox h1 {
        font-size: 6vw;
        margin-top: 3vw;
        margin-bottom: 5vw;
    }

    .hackbox p {
        font-size: 4vw;
        line-height: 5.5vw;
    }

    .hackbox button {
        width: 4vw;
        height: 4vw;
        position: absolute;
        left: -1.6vw;
        margin-top: 4vw;
        border: none;
    }

    .skpw {
        gap: 0;
        overflow: auto;
        display: block;
    }
}